import {
  Content,
  DropdownMenuContentProps,
  DropdownMenuItemIndicatorProps,
  DropdownMenuItemProps,
  DropdownMenuRadioItemProps,
  DropdownMenuSeparatorProps,
  DropdownMenuTriggerProps,
  Item,
  ItemIndicator,
  Portal,
  RadioGroup,
  RadioItem,
  Root,
  Separator,
  Trigger,
} from '@radix-ui/react-dropdown-menu';
import { Slottable } from '@radix-ui/react-slot';
import clsx from 'clsx';
import { ReactNode, forwardRef } from 'react';
import { space } from '../tokens';
import {
  dropdownMenuContent,
  dropdownMenuItem,
  dropdownMenuItemIndicator,
  dropdownMenuSeparator,
} from './dropdown-menu.css';
import { CheckIcon } from '../icons/react/check';

export const DropdownMenuRoot = Root;

export const DropdownMenuTrigger = forwardRef<
  HTMLButtonElement,
  DropdownMenuTriggerProps
>(function DropdownMenuTrigger(props, ref) {
  return <Trigger asChild ref={ref} {...props} />;
});

export const DropdownMenuContent = forwardRef<
  HTMLDivElement,
  DropdownMenuContentProps
>(function DropdownMenuContent({ className, ...rest }, ref) {
  return (
    <Portal>
      <Content
        ref={ref}
        className={clsx(dropdownMenuContent, className)}
        side="bottom"
        align="start"
        collisionPadding={space.s12}
        sideOffset={space.s4}
        {...rest}
      />
    </Portal>
  );
});

export const DropdownMenuItem = forwardRef<
  HTMLDivElement,
  DropdownMenuItemProps & { icon?: ReactNode }
>(function DropdownMenuItem({ className, icon, children, ...rest }, ref) {
  return (
    <Item ref={ref} className={clsx(dropdownMenuItem, className)} {...rest}>
      {icon}
      <Slottable>{children}</Slottable>
    </Item>
  );
});

export const DropdownMenuSeparator = forwardRef<
  HTMLDivElement,
  DropdownMenuSeparatorProps
>(function DropdownMenuSeparator({ className, ...props }, ref) {
  return (
    <Separator
      ref={ref}
      className={clsx(dropdownMenuSeparator, className)}
      {...props}
    />
  );
});

export const DropdownMenuRadioGroup = RadioGroup;

export const DropdownMenuRadioItem = forwardRef<
  HTMLDivElement,
  DropdownMenuRadioItemProps & { icon?: ReactNode }
>(function DropdownMenuRadioItem({ className, icon, children, ...rest }, ref) {
  return (
    <RadioItem
      ref={ref}
      className={clsx(dropdownMenuItem, className)}
      {...rest}
    >
      {icon}
      {children}
      <DropdownMenuItemIndicator>
        <CheckIcon />
      </DropdownMenuItemIndicator>
    </RadioItem>
  );
});

export const DropdownMenuItemIndicator = forwardRef<
  HTMLDivElement,
  DropdownMenuItemIndicatorProps
>(function DropdownMenuItemIndicator({ className, ...rest }, ref) {
  return (
    <ItemIndicator
      ref={ref}
      className={clsx(dropdownMenuItemIndicator, className)}
      {...rest}
    />
  );
});
